import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import api from '../api';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      previewName: '',
      selectedSongUUIDs: [],
      selectedSongs: [],
      selectedPerson: {},
      editor: null,
      signedFields: {},
      testImage: '',
      globalSchedule: true,
      user: null,
      selectedTemplate: null,
      editedDocument: {},
      editedEmail: {},
      saveInterval: null,
      requiredFields: {},
      showSpinner: false,
      signingUser: ''
  },
  getters:{

      availableNames: state => state.selectedSongs.length && state.selectedSongs.reduce(
              (people, song) => {
                if(!song.people.some(songPerson => people.some(person => person.uuid === songPerson.uuid))){
                    return [...people, ...song.people]
                }else{
                    return people;
                }
              }, []).map(person => (
                  {
                      name: person.name,
                      email: person.email,
                      uuid: person.uuid
                  })
      ),
      songsForSelectedPerson: state =>
          (state.selectedPerson || state.globalSchedule)
          &&  state.selectedSongs
              .filter(song => state.globalSchedule || song.people.some(person => person.uuid === state.selectedPerson.uuid))
              .map(song => ({
                  name: song.name,
                  artist: song.artist,
                  royalties: song.people,
                  uuid: song.uuid
              }))
  },
  mutations: {

      setSelectedPerson(state, person){
          state.selectedPerson = person
      },
      setActiveEditor(state, editor){
          state.editor = editor;
      },




      setSelectedSongUUIDs(state, songs){
          state.selectedSongUUIDs = songs;
      },
      setSelectedSongs(state, songs){
          state.selectedSongs = songs;
      },
      setSignedField(state, field){
          state.signedFields[field.uuid] = field.value;
      },
      setRequiredField(state, field){
          Vue.set(state.requiredFields, field.uuid, field.value);
      },
      setGlobalSchedule(state, globalSchedule){
          state.globalSchedule = globalSchedule;
      },
      testImage(state, testImage){
          state.testImage = testImage;
      },
      setEditedDocument(state, document){
          state.editedDocument = {...state.editedDocument, ...document};
      },
      setEditedEmail(state, email){
          state.editedEmail = email;
          state.editedDocument = {...state.editedDocument, ...email};
      },
      resetEditedDocument(state){
        state.editedDocument = {};
        state.editedEmail = {};
      },
      setEditedDocumentFromApi(state, document){
        state.editedDocument = {
            id: document.id,
            name: document.name,
            description: document.description,
            status: document.status,
            signee: document.signee,
            songs: document.songs,
            document: {
                html: document.html,
                json: JSON.parse(document.json),
            },
            email: {
                html: document.email_html,
                json: JSON.parse(document.email_json),
                subject: document.email_subject
            }
        }
      },
      setSaveInterval(state, interval){
          state.saveInterval = interval;
      },
      setSigningUser(state, signee){
          state.signingUser = signee;
      },
      setUser(state, user){
          state.user = user;
          if(user && user.roles){
              state.user.role = user.roles.SONOCUTS_CONTRACTS || 'USER';
          }
          if(user && user.token){
              localStorage.setItem('token', user.token);
              const auth = localStorage.getItem('token');
              axios.defaults.headers.common['Authorization'] = `Bearer ${auth}`}
      },
      setTemplate(state, template){
          state.selectedTemplate = template;
      },
      showSpinner(state, show){
          state.showSpinner = show;
      },
  },
  actions: {
      saveDocument(store, document){
          return api.saveDocument(document.id, document.data);
      },
      saveDocumentAndUpdate(store, document){
          const documentData = api.saveDocument(document.id, document);
          store.commit('setEditedDocumentFromApi', documentData);
          return documentData;
      },
      saveTemplate(store, template){
          return api.saveTemplate(template.id, template.data);
      }
  },
  modules: {
  }
})
