<template lang="pug">
    .song(@click="toggleSong" :class="{selected: song.selected, invalid, disabled}")
        .status
          v-icon(v-if="invalid") mdi-alert-circle-outline
          v-icon(v-else-if="disabled") mdi-alert-circle-outline
          v-icon(v-else) mdi-check-circle-outline
        .song-name
            .name {{song.name}}
            .artist {{song.artist}}
        .checkbox-holder
            .date {{formatDate(song.date)}}
            .checkbox
                v-icon mdi-check

</template>

<script>
import {format} from "date-fns";

export default {
    props: ['song'],

    data() {
        return {
        }
    },
    methods:{
        toggleSong(){
          if(!this.song.uuid){
            this.song.selected = false
          }else{
            this.song.selected = !this.song.selected;
          }
          this.$emit('input', this.song);
        },

        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy')
        },
    },


    computed:{

      invalid(){
        return this.song.songwriting !== 100 || this.song.publishing !== 100 || this.song.mastering !== 100;
      },

      disabled(){
        return !this.song.uuid;
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';



%selected{
    background: $color-green--opacity;
    cursor: pointer;
    border-color: $color-green--opacity;

    .status .v-icon{
        //color: #FFFFFF;
    }

    .song-name{
        //color: #FFFFFF;

        .artist{
            color: $color-green--normal;
        }
    }
    .date{
        color: $color-green--normal
    }
}

%selected-amber{
    background: $color-amber--opacity;
    border-color: $color-amber--opacity;

    .status .v-icon{
        //color: #FFFFFF;
    }

    .song-name{

        .artist{
            color: $color-amber--normal;
        }
    }
    .date{
        color: $color-amber--normal;
    }
}

.song{
    display: flex;
    padding: 10px;
    align-items: center;
    border: 1px solid $color-gray--25;
    border-radius: 10px;

    &:hover{
        @extend %selected;
    }



    .status{

        .v-icon{
            font-size: 50px;
            color: $color-green--normal;
            font-weight: bold;
        }
    }

    .song-name{
        width: 100%;
        text-align: left;
        padding-left: 15px;

        div{
            display: block;
        }

        .name{
            font-weight: bold;
        }

        .artist{
            color: $color-gray--75;
        }
    }

    .checkbox{
        width: 19px;
        height: 19px;

        border: 1px solid $color-green--normal;
        display: flex;
        justify-content: center;

        .v-icon{
            color: #FFFFFF;
            font-size: 14px;

            display: none;
        }
     }

    &.selected{
        @extend %selected;

        .checkbox{
            background: $color-green--normal;

            .v-icon{
                display: inline;
            }
        }
    }


    &.invalid{
        border: 1px solid $color-gray--25;

        &:hover{
            @extend %selected-amber;
        }

        .checkbox{
            border: 2px solid $color-amber--normal;
        }

        .status{

            .v-icon{
                color: $color-amber--normal;
            }
        }

        &.selected{
            @extend %selected-amber;

            .checkbox{
                background: $color-amber--normal;
            }
        }

    }

  &.disabled{
    color: #777777;
    opacity: 0.35;
    cursor: not-allowed;

    .song-name {

      .artist {
        color: #777777;
      }
    }

    .date{
      color: #777777;
    }

    .checkbox{
      border: 2px solid #777777;
    }

    .status{

      .v-icon{
        color: #777777;
      }
    }
  }

    .checkbox-holder{
        display: flex;
        align-items: center;

        .date{
            margin-right: 15px;
        }
    }
}
</style>
