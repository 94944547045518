<template lang="pug">
    v-app#app(data-app :class="$route.name")
        .app-spinner-holder(v-if="showSpinner")
            Spinner
        #nav(v-if="!guest && user")
            v-container(fluid)
                v-row
                    v-col(sm="2")
                        #logo
                            img(src="@/assets/images/logo.svg")
                    v-col
                        #links
                            router-link(to="/dashboard")
                                span
                                    v-icon mdi-view-dashboard-outline
                                    span Dashboard
                            router-link(to="/contracts")
                                span
                                    v-icon mdi-file-document-multiple-outline
                                    span Contracts
                            router-link(to="/templates" v-if="user.role === 'ADMIN'")
                                span
                                    v-icon mdi-file-replace-outline
                                    span Templates
                    v-spacer
                    v-col.user(sm="2")
                        #user
                            v-menu(content-class="user-menu" bottom offset-y)
                                template(#activator="{ on, attrs }")
                                    v-btn(text type="link" v-bind="attrs" v-on="on")
                                        v-icon.user-icon mdi-account-outline
                                        span {{user.first_name}}
                                        v-icon.chevron mdi-chevron-down
                                v-list
                                    v-list-item(to="/account")
                                        v-list-item-icon
                                            v-icon mdi-account-group-outline
                                        v-list-item-content
                                            v-list-item-title Account manager
                                    v-list-item(@click="logout")
                                        v-list-item-icon
                                            v-icon mdi-logout
                                        v-list-item-content
                                            v-list-item-title Sign out

        #nav(v-if="guest")
            v-container.guest(fluid)
                v-row
                    v-col(sm="2")
                        #logo
                            img(src="@/assets/images/logo.svg")
                    v-spacer
                    v-col.user(sm="2")
                        #user
                            .name {{ signer.name }}
        router-view
</template>

<script>
import api from '@/api/index';
import Spinner from "@/components/Spinner";

const guestRoutes = ['sign_contract', 'view_contract', 'contract_signed', 'guest_contracts'];

export default {
    components: {Spinner},
    data(){
        return {
            guest: true
        }
    },

    methods:{
        async logout(){
            await api.logout();
            this.$store.commit('setUser', null);
            this.$router.replace('/');
        },
        async getUser(){
            const user = await api.getUser();
            if(user){
                this.$store.commit('setUser', user);
            }
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
        signer() {
            return this.$store.state.selectedPerson;
        },
        showSpinner(){
            return this.$store.state.showSpinner;
        }
    },

    watch:{
        '$route': {
            handler: async function(){
                if(this.$route.name){
                    if(!guestRoutes.includes(this.$route.name) && this.$route.name !== 'auth'){
                        await this.getUser();
                        this.guest = false;
                        if(!this.user){
                            this.$router.replace('/');
                        }
                    }
                    if(this.$route.name === 'auth'){
                        this.guest = false;
                    }
                }
                this.$store.commit('showSpinner', false);
            },
            immediate: true
        },
    }
}

</script>

<style lang="scss">
@import '@/assets/styles/_variables.scss';

html{
    font-size: $font-size-root !important;
}

body{
    background-color: $body-background;
    font-size: $font-size-body;
    color: $body-color;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $body-background;

    &.template_manager{
        background-color: $color-secondary--very-bright;
    }

    &.new_template{
        background-color: $color-secondary--very-bright;
    }
}

.app-spinner-holder{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;

    background-color: $color-primary--opacity;
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner{
        transform: scale(100%);
    }
}

#nav {
    height: 72px;
    background-color: $nav-background;
    color: #FFFFFF;
    //border-bottom: 1px solid $nav-accent-color;
    box-shadow: 0px 4px 15px rgba(35, 35, 100, 0.1);
    //box-shadow: 0 5px 25px 0 rgb(34 41 47 / 10%) !important;


    .user{
        flex-basis: 0;
    }


    .col{
        height: 72px;
        padding-top: 0px;
        padding-bottom: 0px;

        align-items: center;
        display: flex;
    }

    #logo{
        display: flex;
        align-items: center;
    }

    #logo img{
        height: 45px;
    }

    #links{
        height: 100%;
        display: flex;
        align-items: center;


        a{
            height: 100%;
            color: $nav-link-color;
            text-decoration: none;
            margin-right: 35px;
            position: relative;
            display: flex;
            align-items: center;

            .v-icon{
                color: inherit;
                margin-right: 5px;
            }

            .title{

            }

            &.router-link-active{
                color: $nav-link-active;

                &:before{
                    width: calc(100% + 30px);
                    content: '';
                    border-bottom: 3px solid $nav-accent-color;
                    position: absolute;
                    top: 69px;
                    left: -15px;
                }
            }

            &.router-link-exact-active{
                color: $nav-link-active;
            }
        }
    }

    #user{
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid $color-gray--25;

        .v-btn{
            height: 100%;
            color: $nav-link-active;

            &:before{
                display: none;
            }

            &:after{
                display: none;
            }
        }

        .v-icon{
            color: inherit;
        }

        .v-icon.user-icon{
            margin-right: 5px;
        }

        .v-icon.chevron{
            margin-left: 15px;
        }
    }
}

#nav a {
    font-weight: bold;
}

#nav a.router-link-exact-active {
    //color: #42b983;
}

#nav .guest .user {

    #user{
        align-items: center;
        width: 100%;

        .name{
            color: $color-gray--100;
            width: 100%;

            text-align: center;
            font-size: 16px;
        }
    }
}

.v-menu__content.user-menu{
    width: 300px;
    left: auto !important;
    right: 0px;
    border-radius: 0px;
    border-top: 1px solid $nav-accent-color;


    .v-list{
        background: $nav-background;

        .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
            color: $nav-link-active;

            .v-icon{
                color: inherit;
            }
        }
    }
}

#app .v-sheet.v-card{
    border-radius: 10px;

    .v-card__title{
        font-size: $font-size-heading--3;
        //border-bottom: 1px solid $color-gray--25;
    }

    .v-card__text{
        padding-top: 16px;
    }
}
</style>
