import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Auth from '../views/Auth/Auth.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import store from '../store/index';
import api from "../api";

Vue.use(VueRouter)

const blockUser = (to, from, next) => {
    console.log(store.state.user);
    if(!store.state.user){
        api.getUser().then(res => {
            store.commit('setUser', res);
            if(store.state.user.role !== 'USER'){
                next();
            }else{
                next('/dashboard');
            }
        })
    }else{
        if(store.state.user.role !== 'USER'){
            next()
        }else{
            next('/dashboard');
        }
    }
}

const routes = [
  {
    path: '/',
    name: 'auth',
    component: Auth
  },

  {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
  },
{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
},

    {
        path: '/template/:id',
        name: 'edit_template',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/Document.vue'),
        beforeEnter: blockUser,
    },

    {
        path: '/contracts',
        name: 'documents_manager',
        component: () => import(/* webpackChunkName: "documents" */ '../views/Documents/DocumentsManager.vue')
    },

    {
        path: '/contracts/new',
        name: 'new_contract',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/DocumentEdit.vue')
    },

    {
        path: '/contracts/status/:status',
        name: 'documents_manager_status',
        component: () => import(/* webpackChunkName: "documents" */ '../views/Documents/DocumentsManager.vue')
    },

    {
        path: '/contracts/sent',
        name: 'contract_sent',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/DocumentSent.vue')
    },

    {
        path: '/contracts/:id',
        name: 'edit_contract',  
        component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/DocumentEdit.vue')
    },

    {
        path: '/contracts/:id/preview',
        name: 'preview_contract',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/DocumentPreview.vue')
    },

    {
        path: '/templates',
        name: 'template_manager',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Templates/TemplatesManager.vue'),
        beforeEnter: blockUser,
    },

    {
        path: '/templates/new',
        name: 'new_template',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Templates/TemplateEdit.vue'),
        beforeEnter: blockUser,
    },

    {
        path: '/templates/:id',
        name: 'template_edit',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Templates/TemplateEdit.vue'),
        beforeEnter: blockUser,
    },

    {
        path: '/account/',
        name: 'account_manager',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Account/AccountManager.vue'),
        beforeEnter: blockUser,
    },


    {
    path: '/contract/new',
    name: 'new_contract',
    component: () => import(/* webpackChunkName: "documents" */ '../views/Documents/Document.vue'),
    beforeEnter: (to, from, next) => {
        if(store.state.selectedSongUUIDs.length > 0){
            next()
        }else{
            next({ name: 'dashboard' })
        }
    },
    },


{
    path: '/guest/contracts/:id',
    name: 'guest_contracts',
    component: () => import(/* webpackChunkName: "documentSign" */ '../views/Documents/SignerDocuments.vue'),
},

{
    path: '/contract/sign/:id',
    name: 'sign_contract',
    component: () => import(/* webpackChunkName: "documentSign" */ '../views/Documents/DocumentSign.vue'),
},

{
    path: '/contract/view/:id',
    name: 'view_contract',
    component: () => import(/* webpackChunkName: "documentSign" */ '../views/Documents/DocumentView.vue'),
},

{
    path: '/contract/signed',
    name: 'contract_signed',
    component: () => import(/* webpackChunkName: "templates" */ '../views/Documents/DocumentSigned.vue')
},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
