<template lang="pug">
  .auth
      v-card
        v-card-text
            #logo
                img(src="@/assets/images/logo.svg")
            .error-message(v-if="error") The credentials you provided are not valid
            .login
                v-text-field(v-model="username" placeholder="Email address" @keydown.enter="login" @input="error = false")
                v-text-field(v-model="password" type="password" placeholder="Password" @keydown.enter="login" @input="error = false")
                v-btn(color="primary" @click="login" block) Login


</template>

<script>

import api from "../../api";

export default {

    components: {
    },

    data(){
        return {
            username: '',
            password: '',

            error: false
        }
    },
    methods: {
        async login() {
            const user = await api.login(this.username, this.password);
            if (user.id) {
                this.$store.commit('setUser', user);
                this.$router.replace('dashboard');
            }else{
                this.error = true;
            }
        },
    },

    beforeMount() {
       if(this.user){
           this.$router.replace('dashboard');
       }
    },

    computed: {
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.error-message{
    padding-top: 15px;
    color: $color-error;

    text-align: center;
}
.auth{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}
.v-card{
    width: 500px;
    height: 500px;
    display: inline-flex;
    padding: 50px;
}

.login{

    .v-text-field{
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

#logo img{
    width: 100%;
    margin-bottom: 15px;
}

</style>
