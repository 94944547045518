<template lang="pug">
    v-card.widget
        v-card-title {{title}}
            slot(name="title")
        v-card-text
            slot
</template>

<script>
export default {
    props: ['title'],

    data() {
        return {
            editor: null,
            person: null,
        }
    }
}
</script>

<style lang="scss" scoped>
#app .v-sheet.v-card{

    .v-card__text{
        padding-top: 0px;
    }
}
</style>
