import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';

if(localStorage.getItem('token')){
    const auth = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth}`
}

let api = 'http://api.roy.jsxdev.local/api/v1';
// api = 'https://api.roy.jsxnet.ro/api/v1';
api  = 'https://api.sonocuts.com/api/v1';

export default {
    api: api,

    async getUser() {
        try{
            const response = await axios.get(`${api}/sso/user`);
            return response.data;
        }catch (e){
            console.log(e);
        }
    },

    async login(email, password) {
        const response = await axios.get(`${api}/sso/login`, {
            params: {email, password}
        });
        return response.data;
    },

    async getUsers() {
        try{
            const response = await axios.get(`${api}/sso/users`);
            return response.data;
        }catch (e){
            console.log(e);
        }
    },


    async saveUser(user) {
        let id = user.id;
        if(!user.id){
            id = 'new';
        }
        try{
            const response = await axios.post(`${api}/sso/users/${id}`,
                {
                    user: user
                }
            );
            return response.data;
        }catch (e){
            console.log(e);
        }
    },


    async deleteUser(id) {
        try{
            const response = await axios.delete(`${api}/sso/users/${id}`);
            return response.data;
        }catch (e){
            console.log(e);
        }
    },


    async logout() {
        const response = await axios.get(`${api}/sso/logout`);
        localStorage.removeItem('token');
        return response.data;
    },

    async getTemplates(params) {
        const response = await axios.get(`${api}/documents/templates`, {
            params: params
        });
        return response.data;
    },

    async getDocuments(params) { 
        const response = await axios.get(`${api}/documents`, {
            params: params
        });
        return response.data;
    },

    async getDocumentsForGuest(id) {
        const response = await axios.get(`${api}/guest/documents/list/${id}`);
        return response.data;
    },

    async getSongs() {
        const response = await axios.get(`${api}/songs/simple`);
        return response.data;
    },

    async getFullSongs(params) {
        const response = await axios.get(`${api}/songs/full`, {
            params: params
        });
        return response.data;
    },

    async getSongsByUUID(uuids) {
        const response = await axios.get(`${api}/songs/byUUID`, {
            params: {
                uuids: uuids
            }
        });
        return response.data;
    },

    async getArtists() {
        const response = await axios.get(`${api}/songs/artists`);
        return response.data;
    },

    async getTemplatesViaApi() {
        const response = await axios.get(`${api}/documents/templates`);
        return response.data;
    },

    async getTemplate(id) {
        const response = await axios.get(`${api}/documents/templates/${id}`);
        return response.data;
    },

    async deleteTemplate(id) {
        const response = await axios.delete(`${api}/documents/templates/${id}`);
        return response.data;
    },

    async getDocument(id) {
        const response = await axios.get(`${api}/documents/${id}`);
        return response.data; 
    },

    async getActivities(id) {
        const response = await axios.get(`${api}/documents/activities`);
        return response.data;
    },

    async getActivityTypes() {
        const response = await axios.get(`${api}/documents/activities/types`);
        return response.data;
    },

    async submitDocument(params){
        const response = await axios.post(`${api}/documents/new`, params);
        return response.data;
    },

    async newTemplate(params){
        const response = await axios.post(`${api}/documents/templates`, params);
        return response.data;
    },

    async saveTemplate(id, params){
        const response = await axios.post(`${api}/documents/templates/${id}`, params);
        return response.data;
    },

    async saveDocument(id, params){
        const response = await axios.post(`${api}/documents/${id}`, params);
        return response.data;
    },

    async sendDocument(id, params){
        const response = await axios.post(`${api}/documents/${id}/send`, params);
        return response.data;
    },

    async deleteDocument(id) {
        const response = await axios.delete(`${api}/documents/${id}`);
        return response.data;
    },

    async downloadDocumentAsPdf(id, name){
        axios({
            url: `${api}/documents/${id}/download`,
            method: 'GET',
            responseType: 'blob',
        }).then((res) => {
            var FILE = window.URL.createObjectURL(new Blob([res.data]));
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', `${name}.pdf`);
            document.body.appendChild(docUrl);
            docUrl.click();
        });
    },

    async signDocument(params, id){
        const response = await axios.post(`${api}/guest/documents/sign/${id}`, params);
        return response.data;
    },

    async getDocumentForSigning(id){
        const response = await axios.get(`${api}/guest/documents/${id}`);
        return response.data;
    }
}

