<template lang="pug">
    .recent-songs-widget
        DashboardWidget(title="Recently added songs")
            template(#title)
                v-spacer
                v-btn(outlined color="primary" :disabled="!selectedSongs.length" @click="newDocument")
                    span(v-if="selectedSongs.length") New contract with {{selectedSongs.length}} song(s)
                    span(v-else) New contract with selected songs
            v-row(v-if="songs.length")
                v-col(v-for="song in songs" sm="4")
                    Song(:song="song")
            .table-spinner(v-else)
                Spinner

</template>

<script>
import api from '@/api';
import DashboardWidget from "./DashboardWidget";
import Song from "../Song";
import Spinner from "../Spinner";

export default {
    components: {Spinner, Song, DashboardWidget},
    data() {
        return {
            songs: [],
            songParams: {
                sort: 'date',
                desc: true,
                paginate: 15
            },
        }
    },
    computed: {
        selectedSongs(){
            return this.songs.filter(song => song.selected);
        }
    },
    methods: {
        async getSongs(){
            const songs = await api.getFullSongs(this.songParams);
            songs.items.forEach(song => song.selected = false);
            this.songs = songs.items;
        },
        newDocument(){
            this.$store.commit('setSelectedSongs', this.selectedSongs);
            this.$router.push('/contracts/new');
        }
    },
    beforeMount() {
        this.getSongs();
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.recent-songs-widget{

    .table-spinner{
        display: flex;
        justify-content: center;
        align-items: center;

        height: 350px;
        margin-top: 15px;

        .spinner{
            filter: hue-rotate(-85deg) brightness(195%) saturate(70%);
        }
    }

}

.recent-songs-widget::v-deep  .v-sheet.v-card{

    .v-card__text{
        height: calc(100% - 65px);
        padding-top: 16px !important;
    }
}

</style>
