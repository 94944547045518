<template lang="pug">
    .dashboard-updates-widget
        DashboardWidget(title="Latest updates")
            perfect-scrollbar
                v-timeline(align-top dense)
                    v-timeline-item(:color="activityTypes[activity.type].color" small v-for="activity in activities")
                        strong(:class="activity.type")
                            router-link(:to="{name: 'edit_contract', params: {id: activity.document_id}}" v-if="activity.document_id") {{activity.document || '[Untitled]'}}&nbsp;
                        span {{activityTypes[activity.type].template}}
                        strong(:class="activity.type" v-if="activity.email_id") &nbsp;{{activity.person}}
                        div.date {{formatDate(activity.timestamp)}}
                        div.download(v-if="activity.type === 'signed'" @click="downloadPdf(activity)") Download copy as PDF

</template>

<script>
import api from '@/api';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import DashboardWidget from "./DashboardWidget";
import {format} from "date-fns";
export default {
    components: {DashboardWidget, PerfectScrollbar},
    data() {
        return {
            editor: null,
            person: null,
            activities: [],
            activityTypes: {
                draft: {
                    template: 'was added as a draft',
                    color: 'secondary'
                },
                sent: {
                    template: 'was sent for signing',
                    color: 'primary'
                },
                read: {
                    template: 'was read by',
                    color: 'amber'
                },
                signed: {
                    template: 'was signed by',
                    color: 'green'
                },
            }
        }
    },
    methods: {
        formatDate(date){
            return format(new Date(date), 'MM/dd/yyyy hh:mmaaa')
        },
        async downloadPdf(activity){
            await api.downloadDocumentAsPdf(activity.email_id, `${activity.document} - ${activity.person}`);
        }
    },
    async beforeMount(){
        this.activities = await api.getActivities();
    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.dashboard-updates-widget{

    ::v-deep .v-sheet.v-card{
        height: 500px;
        overflow: hidden;

        .v-card__text{
            height: calc(100% - 65px);
        }
    }

    .v-timeline-item{
        font-size: 1rem;
        color: $color-gray--100;

        .date{
            font-size: $font-size-small;
            color: $color-gray--75;
        }

        strong.draft{
            color: $document-draft-color;
        }

        strong.sent{
            color: $document-sent-color;
        }

        strong.read{
            color: $document-read-color;
        }

        strong.signed{
            color: $document-signed-color;
        }

        strong a{
            text-decoration: none;
            color: inherit;
        }


        div.download{
            font-size: 0.75rem;
            text-decoration: none;
            color: $color-primary--normal;
            cursor: pointer;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.ps {
    height: 100%;
}
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
