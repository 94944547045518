export default {

    primaryFaded: {
        base: '#6288d4',
    },

    secondaryFaded: {
        base: '#6da693',
    },

    accentFaded: {
        base: '#d96d70',
    },

    amberFaded: {
        base: '#bfa460',
    },
    // primary: '#6288d4',
    // secondary: '#6da693',
    // accent: '#d96d70',
    // amber: '#d9ae41',
    primary: {
        base: '#282873',
        lighten1: '#d1d1f6',
        lighten2: '#fafaff'
    },


    secondary: {
        base: '#00b9bf',
        lighten1: '#D7F1F9',
        lighten2: '#f2f8fa'
    },
    accent: {
        base: '#ed5e51',
        lighten1: '#fbd9d6'
    },
    amber: {
        base: '#ad5618',
        lighten1: '#ffe7d1'
    },
    green: {
        base: '#14ad38',
        lighten1: '#d6f6de'
    },



    primaryLight: '#d1d1f6',
    secondaryLight: '#d7d3f4',
    accentLight: '#fbd9d6',
    amberLight: '#f7d1ca',
    verdeLight: '#d6f6de',
    /*
        primary: {
        lighten2: '#e6efff',
        lighten1: '#B8D1F9',
        base: '#3E82F0',
        darken1: '#0E4FB9'
    },
    secondary: {
        lighten2: '#c2f2e2',
        lighten1: '#abe6b6',
        base: '#22D09A',
        darken1: '#19966F',
        darken2: '#0a7255'
    },
    accent: {
        lighten2: '#FFDDDF',
        lighten1: '#FFB3B5',
        base: '#E43E44',
        darken1: '#A1171D'
    },
    amber: {
        lighten2: '#ffedbf',
        lighten1: '#FFE7AA',
        base: '#FFD464',
        darken1: '#EEAA00',
        darken2: '#FFBF1A'
    },
     */

}

