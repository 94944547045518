<template lang="pug">
  .dashboard
      v-container
        v-row
            v-col(sm="4")
                DocumentsOverview
            v-col(sm="8")
                DashboardUpdates
            v-col
                RecentSongs
</template>

<script>
import api from '@/api';
import DocumentsOverview from "../../components/dashboard/DocumentsOverview";
import DashboardUpdates from "../../components/dashboard/DashboardUpdates";
import RecentSongs from "../../components/dashboard/RecentSongs";

export default {

    components: {
        RecentSongs,
        DashboardUpdates,
        DocumentsOverview,
    },

    data(){
        return {
        }
    },

    methods:{
    },

    computed:{
        user(){
            return this.$store.state.user;
        }
    },
}
</script>
<style lang="scss" scoped>
.dashboard{
    padding-top: 50px;
}

::v-deep{
    .v-data-table-header{
        th{
            text-align: left;
        }
    }
}
</style>
