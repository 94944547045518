<template lang="pug">
    .document-overview-widget
        DashboardWidget(title="Contract overview")
            .documents(v-if="activityTypes")
                .document.draft(@click="loadDocumentManager('draft')")
                    .value
                        .number {{ activityTypes.draft }}
                        .type Draft
                .document.sent(@click="loadDocumentManager('sent')")
                    .value
                        .number {{ activityTypes.sent }}
                        .type Sent
                .document.read(@click="loadDocumentManager('read')")
                    .value
                        .number {{ activityTypes.read }}
                        .type Read
                .document.signed(@click="loadDocumentManager('signed')")
                    .value
                        .number {{ activityTypes.signed }}
                        .type Signed
</template>

<script>
import api from '@/api';

import DashboardWidget from "./DashboardWidget";
export default {
    components: {DashboardWidget},
    data() {
        return {
            activityTypes: null,
        }
    },
    methods: {
        loadDocumentManager(status){
            this.$router.push({name: 'documents_manager_status', params: {status: status}})
        }
    },
    async beforeMount() {
        this.activityTypes = await api.getActivityTypes();
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.document-overview-widget{

    ::v-deep .v-sheet.v-card{
        height: 500px;

        .v-card__text{
            height: calc(100% - 65px);
            padding: 0px;
        }
    }

    .documents{
        height: 100%;
        display: flex;
        flex-wrap: wrap;

        .document{
            width: 50%;
            height: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            line-height: 1;
            transition: all 0.15s;

            &:hover{
                color: #FFFFFF;
                cursor: pointer;

                .number{
                    color: #FFFFFF !important;
                }
            }

            &.draft{
                border-right: 1px solid $color-gray--75;
                border-bottom: 1px solid $color-gray--75;

                &:hover{
                    background: $document-draft-color;
                }

                .number{
                    color: $document-draft-color;
                }
            }

            &.sent{
                border-bottom: 1px solid $color-gray--75;

                &:hover{
                    background: $document-sent-color;
                }

                .number{
                    color: $document-sent-color;
                }
            }

            &.read{
                border-right: 1px solid $color-gray--75;

                &:hover{
                    background: $document-read-color;
                }

                .number{
                    color: $document-read-color;
                }
            }

            &.signed{

                &:hover{
                    background: $document-signed-color;
                }

                .number{
                    color: $document-signed-color;
                }
            }

            .value{
                margin-left: auto;
                margin-right: auto;
            }

            .number{
                font-size: $font-size-heading--0;
                margin-bottom: 15px;

                transition: all 0.15s;
            }

            .type{

            }
        }
    }
}

</style>
